/* eslint-disable no-console */
/* eslint-disable import/prefer-default-export */
import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';
import typography from './typography';
import overrides from './overrides';
import palette from './palette';

const baseConfig = {
  direction: 'ltr',
  typography,
  palette,
  overrides
  // breakpoints: {
  //   values: {
  //     xs: 0,
  //     sm: 480,
  //     md: 768
  // lg: 1024,
  // xl: 1280
  //   }
  // }
};

// export function createTheme(settings = {}) {
//   let theme = createMuiTheme(baseConfig);
//   theme = responsiveFontSizes(theme);
//   return theme;
// }
const theme = createTheme(baseConfig);
// const theme = createTheme();
export default responsiveFontSizes(theme);

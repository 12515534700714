import {useMemo} from 'react';
import {BatchHttpLink} from '@apollo/client/link/batch-http';
import cookie from 'js-cookie';
// eslint-disable-next-line no-unused-vars
import {ApolloClient, InMemoryCache} from '@apollo/client';
import {relayStylePagination} from '@apollo/client/utilities';
import {onError} from '@apollo/client/link/error';
import {setContext} from '@apollo/client/link/context';
import * as Sentry from '@sentry/browser';
import {from} from '@apollo/client';

import {AUTH_TOKEN, isClientSide} from 'src/config';

import {resolvers} from './resolvers';

let apolloClient;

function createApolloClient(initialState = null) {
  let authToken = initialState ? initialState.token : null;
  const authLink = setContext((_, context) => {
    // set ORIGIN manually for SSR requests
    context.headers = {
      ...context.headers,
      Origin: process.env.ORIGIN
    };

    if (isClientSide()) {
      authToken = cookie.get(AUTH_TOKEN);
    }

    if (authToken) {
      return {
        ...context,
        headers: {
          ...context.headers,
          Authorization: authToken ? `JWT ${authToken}` : null
        }
      };
    }

    return context;
  });
  // const link = ApolloLink.from([
  //   authLink,
  //   new BatchHttpLink({ uri: process.env.BACKEND_URL })
  // ]);

  let backendURI = process.env.BACKEND_SERVICE_URL || process.env.BACKEND_URL;

  if (isClientSide()) {
    backendURI = process.env.BACKEND_URL;
  }

  const linkOptions = {
    credentials: 'include',
    uri: backendURI
  };
  const batchLink = new BatchHttpLink({...linkOptions, batchInterval: 100});

  const cache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          // https://www.apollographql.com/docs/react/pagination/cursor-based/#relay-style-cursor-pagination
          prizeProductsStorefront: relayStylePagination()
        }
      }
    }
  });

  const errorLink = onError(({graphQLErrors, networkError}) => {
    if (graphQLErrors) {
      graphQLErrors.map(({extensions}) => Sentry.captureMessage(extensions));
    }

    if (networkError) {
      Sentry.captureException(networkError.result);
    }
  });

  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: from([authLink, errorLink, batchLink]),
    cache,
    connectToDevTools: process.env.DEBUG,
    resolvers
  });
}

export function initializeApollo(initialState = null) {
  const _apolloClient = apolloClient ?? createApolloClient(initialState);

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache that might have loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    if (existingCache.ROOT_QUERY && initialState.ROOT_QUERY) {
      existingCache.ROOT_QUERY = {
        ...existingCache.ROOT_QUERY,
        ...initialState.ROOT_QUERY
      };
    }

    // Restore the cache with the initialState passed from getStaticProps/getServerSideProps combined with the existing cached data
    _apolloClient.cache.restore({...existingCache, ...initialState});
  }

  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') {
    return _apolloClient;
  }

  // Create the Apollo Client once in the client
  if (!apolloClient) {
    apolloClient = _apolloClient;
  }

  return _apolloClient;
}

export function useApollo(initialState) {
  return useMemo(() => initializeApollo(initialState), [initialState]);
}

import blue from '@material-ui/core/colors/blue';
import blueGrey from '@material-ui/core/colors/blueGrey';
import grey from '@material-ui/core/colors/grey';

const white = '#FFFFFF';

export default {
  link: blue[800],
  icon: blueGrey[600],
  divider: grey[200],
  common: {
    black: 'rgba(0, 0, 0, 1)',
    white,
    // these are new primary colors
    primary: '#0F1729',
    secondary: '#e9b109'
  },
  background: {
    paper: '#fff',
    default: '#fafafa'
  },
  primary: {
    light: 'rgba(114, 148, 202, 1)',
    main: 'rgba(14, 71, 152, 1)',
    dark: 'rgba(20, 34, 65, 1)',
    contrastText: '#fff'
  },
  secondary: {
    light: 'rgba(242, 201, 76, 1)',
    main: 'rgba(242, 201, 76, 1)',
    dark: 'rgba(242, 201, 76, 1)',
    contrastText: '#fff'
  },
  error: {
    light: 'rgba(254, 221, 221, 1)',
    main: 'rgba(214, 32, 32, 1)',
    dark: '#d32f2f',
    contrastText: '#fff'
  },
  text: {
    primary: 'rgba(51, 51, 51, 1)',
    // secondary: "rgba(3, 38, 87, 1)",
    secondary: 'rgba(255, 255, 255, 1)',
    disabled: 'rgba(0, 0, 0, 0.38)',
    hint: 'rgba(0, 0, 0, 0.38)'
  }
};

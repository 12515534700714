export default {
  // _root: {
  //   height: 50,
  //   marginTop: 5,
  //   marginBottom: 5,
  //   boxShadow: 'inset 2px 2px 6px rgba(0, 0, 0, 0.25)',
  //   color: 'rgba(51, 51, 51, 1)',
  //   borderRadius: 5,
  //   fontSize: 20,
  //   '&$input:focus::placeholder': {
  //     color: 'transparent !important'
  //   },
  //   backgroundColor: '#F2F2F2',
  //   '&:hover': {
  //     backgroundColor: '#F2F2F2'
  //   },
  //   '&:focus-within': {
  //     backgroundColor: '#F2F2F2'
  //   },
  //   '&$disabled': {
  //     backgroundColor: '#CCCCCC'
  //   }
  // },
  input: {
    paddingTop: 14,
    paddingBottom: 13
  },
  inputMarginDense: {
    paddingTop: 9,
    paddingBottom: 8
  }
};

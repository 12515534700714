import cookie from 'js-cookie';

import {
  AUTH_TOKEN,
  CHECKOUT_KEY,
  CURRENCY_KEY,
  DEFAULT_CURRENCY
} from '../src/config';
import {CheckoutUpdateMutation} from '../src/views/CheckoutNext/mutations';
import {CurrencyQuery} from '../src/gql/queries/goldProducts';
import {IsLoggedInQuery} from '../src/gql/queries/account';

export const resolvers = {
  Mutation: {
    updateCurrency(obj, args, context, info) {
      const checkout = JSON.parse(localStorage.getItem(CHECKOUT_KEY));

      // update checkout currency in case if checkout itself exists
      if (!!checkout && checkout.id) {
        context.client.mutate({
          mutation: CheckoutUpdateMutation,
          variables: {id: checkout.id, input: {currency: args.currency}}
        });
      }

      context.cache.writeQuery({
        query: CurrencyQuery,
        data: {currency: args.currency}
      });
      cookie.set(CURRENCY_KEY, args.currency, {expires: 99999});
      return null;
    },
    verifyToken(obj, args, context, info) {
      console.log('VerifyToken on SRR');
      return null;
    }
  },
  Query: {
    isLoggedIn(obj, args, context, info) {
      const {isLoggedIn} = context.cache.readQuery({
        query: IsLoggedInQuery
      });
      return typeof window === 'undefined'
        ? isLoggedIn // read value from apollo cache which was set on line 93
        : !!cookie.get(AUTH_TOKEN); // but check cookies on clientSide to be sure for 100%
    },
    currency(obj, args, context, info) {
      // localStorage is no accesible on SSR so use it will raise an error on SSR
      // return typeof window === "undefined" ? DEFAULT_CURRENCY : localStorage.getItem(CURRENCY_KEY);
      const {currency} = context.cache.readQuery({
        query: CurrencyQuery
      });
      const currencyDefault = currency || DEFAULT_CURRENCY;
      return typeof window === 'undefined'
        ? currencyDefault
        : cookie.get(CURRENCY_KEY);
    }
  }
};

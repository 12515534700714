import {gql} from '@apollo/client';

import {CheckoutFragment} from '../CheckoutNext/queries';

export const UserFragment = gql`
  fragment UserFragment on User {
    id
    email
    fullName
    isStaff
    isActive
    isSuperuser
    is2faEnabled
    dateJoined
    currency
    loyaltyDetails {
      id
      spent {
        localized
      }
      spentPoints
      points
      progress
      spendToNextLevel {
        localized
      }
      nextLevel {
        name
        image(size: "130x130") {
          url
        }
      }
      currentLevel {
        id
        name
        image(size: "130x130") {
          url
        }
        pointsPerUsd
        benefits {
          title
          description
        }
      }
    }
  }
`;

export const CheckoutCustomerDetachMutation = gql`
  ${CheckoutFragment}
  mutation CheckoutCustomerDetach($checkout: ID!) {
    checkoutCustomerDetach(checkoutId: $checkout) {
      errors {
        field
        message
      }
      checkout {
        ...CheckoutFragment
      }
    }
  }
`;

export const VerifyOTPMutation = gql`
  mutation VerifyOTP($otp: String!, $secret: String!, $authType: AuthType!) {
    verifyOtp(otp: $otp, secret: $secret, authType: $authType) {
      accessToken
      secret
      errors {
        field
        message
        code
      }
    }
  }
`;

export const TokenCreateMutation = gql`
  ${UserFragment}
  mutation TokenCreate(
    $email: String!
    $password: String!
    $accessToken: String
  ) {
    tokenCreate(email: $email, password: $password, accessToken: $accessToken) {
      token
      secret
      user {
        ...UserFragment
      }
      errors {
        field
        message
        code
      }
    }
  }
`;

// export const TokenRefreshMutation = gql`
//   mutation TokenRefresh($refreshToken: String!) {
//     tokenRefresh(refreshToken: $refreshToken) {
//       token
//       refreshToken
//     }
//   }
// `;

export const TokenDestroyMutation = gql`
  mutation TokenDestroy {
    tokenDestroy {
      errors {
        field
        message
      }
    }
  }
`;

export const CheckoutCustomerAttachMutation = gql`
  ${CheckoutFragment}
  mutation CheckoutCustomerAttach($checkout: ID!, $customer: ID!) {
    checkoutCustomerAttach(checkoutId: $checkout, customerId: $customer) {
      errors {
        field
        message
      }
      checkout {
        ...CheckoutFragment
      }
    }
  }
`;

export const TokenVerifyMutation = gql`
  ${UserFragment}
  mutation VerifyToken($token: String!) {
    tokenVerify(token: $token) {
      payload
      user {
        ...UserFragment
      }
    }
  }
`;

export const AccountRegisterMutation = gql`
  mutation AccountRegister($input: AccountRegisterInput!) {
    accountRegister(input: $input) {
      user {
        email
        fullName
      }
      errors {
        field
        message
      }
      accountErrors {
        field
        message
        code
      }
    }
  }
`;

import {gql} from '@apollo/client';

export const CurrencyQuery = gql`
  query currency {
    currency @client(always: true)
  }
`;

export const RequestCurrencyQuery = gql`
  query RequestCurrency {
    requestCurrency
  }
`;

export const GoldProductsQuery = gql`
  query goldProducts {
    goldProducts {
      id
      name
      gameVersion
      minBuyQuantity
      minSellQuantity
      buyPrice {
        currency
        amount
        localized
      }
      sellPrice {
        amount
      }
      sellPriceInCurrencies {
        currency
        amount
        localized
      }
      image(size: "220x60") {
        url
      }
    }
  }
`;

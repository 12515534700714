import MuiDivider from './MuiDivider';
import MuiInputAdornment from './MuiInputAdornment';
import MuiFilledInput from './MuiFilledInput';
import MuiOutlinedInput from './MuiOutlinedInput';

export default {
  MuiDivider,
  MuiInputAdornment,
  MuiFilledInput,
  MuiOutlinedInput
  // MuiInputLabel
};
